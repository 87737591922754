<template>
  <v-sheet class="pa-4 comments-wrapper d-flex flex-column">
    <div>
      <KSubtitle v-if="title" class="pa-0">{{ title }}</KSubtitle>
    </div>
    <v-row justify="center"
           no-gutters
           class="comments-timeline-wrapper"
           :class="{'small-timeline' : ableToPlaceComments}">
      <v-col cols="12">
        <v-timeline class="comments-timeline">
          <Comment v-for="(comment, index) in comments"
                   :comment="comment"
                   :key="comment.id"
                   :is-sender="getCurrentUserId === comment.userId"
                   :is-date-visible="hasDateChip(index)"/>
        </v-timeline>
        <v-row class="justify-center" no-gutters>
          <v-btn text
                 @click="loadMore"
                 :loading="loadingMore"
                 :disabled="page >= lastPage"
                 class="mr-4 mt-2 text-center"
                 color="primary">
            {{ $t('actions.loadMore') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="comment-text-wrapper">
      <v-col cols="12" v-if="ableToPlaceComments">
        <VTextarea v-model="message"
                   :label="commentsTextAreaLabelText"
                   :rows="3"/>
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn v-if="ableToPlaceComments"
               color="black"
               class="white--text"
               depressed
               tile
               @click="handleCreateMessage"
               :loading="loading">
          {{ addButtonText }}
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import Comment from '@/components/comments/Comment.vue';
import OrderComment from '@/application/models/Comment.js';
import { mapGetters } from 'vuex';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import dayjs from 'dayjs';

export default {
  name: 'Comments',
  components: {
    KSubtitle,
    Comment,
  },
  props: {
    indexRequest: {
      type: Function,
      required: true,
    },
    createRequest: {
      type: Function,
      required: true,
    },
    ableToPlaceComments: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    addButtonText: {
      type: String,
      default: function () {
        return this.$t('actions.add');
      },
    },
    commentsTextAreaLabelText: {
      type: String,
      default: function () {
        return this.$t('actions.add');
      },
    },
  },
  data: () => ({
    comments: [],
    loading: false,
    loadingMore: false,
    message: '',
    page: 1,
    lastPage: 1,
    perPage: 15,
  }),
  computed: {
    ...mapGetters('authorisation', ['getCurrentUserId', 'username']),
  },
  methods: {
    async getComments() {
      this.page = 1;
      const result = await this.getPaginator();
      this.comments = [...result];
    },
    async handleCreateMessage() {
      this.loading = true;
      const response = await this.createRequest(this.message);

      this.comments.unshift(new OrderComment().mapResponse({
        id: new Date().getMilliseconds(),
        comment: this.message,
        userName: this.username,
        userId: this.getCurrentUserId,
        createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      }));

      this.loading = false;
      this.message = '';
    },
    async loadMore() {
      this.page++;
      const result = await this.getPaginator();
      this.comments = [...this.comments, ...result];
    },
    async getPaginator() {
      this.loadingMore = true;
      const response = await this.indexRequest(this.orderId, this.page, this.perPage, this.searchParameter);
      this.lastPage = response.data.meta.lastPage;
      this.loadingMore = false;
      return response.data.data.map(comment => new OrderComment().mapResponse(comment));
    },
    hasDateChip(index) {
      return index === 0 || this.comments[index - 1].date !== this.comments[index].date;
    },
  },
  created() {
    this.getComments();
  },
};
</script>
<style scoped lang="scss">
.comments-wrapper {
  height:   100%;
  overflow: auto;
}

.comments-timeline-wrapper {
  overflow-y: auto;
}

.comment-text-wrapper {
  border-top: 1px solid lightgray;
}
</style>
