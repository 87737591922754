<template>
  <page-layout>
    <template #header>
      <k-title>
        <router-link :to="{name: 'tickets.index'}" class="secondary--text">{{ $t('ticket.tickets.menuTitle') }}
        </router-link>
        / {{ $t('ticket.tickets.details.menuTitle', [ticketId]) }}
      </k-title>
    </template>
    <template #flow-actions>

    </template>
    <template #default>
      <v-fade-transition>
        <v-row class="mt-6">
          <v-col lg="7" cols="12">
            <k-display-group language-prefix="ticket.tickets.display">
              <v-row>
                <v-col cols="12">
                  <v-sheet class="pa-4">
                    <KSubtitle class="pa-0">{{ $t('ticket.tickets.details.ticketDetails') }}</KSubtitle>
                    <VSkeletonLoader v-if="!ticketDetails.isLoaded" type="card-heading"/>
                    <div v-else>
                      <v-row class="mt-2" no-gutters>
                        <v-col cols="4">
                          <k-text-display class="mb-3" field="status">
                            {{ status }}
                          </k-text-display>
                          <k-text-display class="mb-3 text-pre-line" field="location">
                            {{
                            ticketDetails.address.addressLine1 + '\n' + ticketDetails.address.city + '\n' +
                            ticketDetails.address.zipCode + '\n' + $t(`countries.${ticketDetails.address.countryId}`)
                            }}
                          </k-text-display>
                        </v-col>
                        <v-col cols="4">
                          <k-text-display class="mb-3 text-pre-line" field="contactInfo">
                            {{
                              user
                            }}
                          </k-text-display>
                        </v-col>
                        <v-col cols="4">
                          <k-text-display class="mb-3" field="creationDate">
                            {{ ticketDetails.creationDate }}
                          </k-text-display>
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </v-col>
                <v-col cols="12">
                  <v-sheet class="pa-4 fill-height">
                    <KSubtitle class="pa-0">{{ $t('ticket.tickets.details.subjectDetails') }}</KSubtitle>
                    <VSkeletonLoader v-if="!ticketDetails.isLoaded" type="card-heading"/>
                    <div v-else>
                      <v-row class="mt-2" no-gutters>
                        <v-col cols="6">
                          <k-text-display class="mb-3" field="subject">
                            {{ ticketDetails.subject }}
                          </k-text-display>
                          <k-text-display class="mb-3 text-pre-line" field="description">
                            {{ ticketDetails.description }}
                          </k-text-display>
                          <span class="d-inline-flex k-label text--secondary font-italic"
                                v-if="ticketDetails.relatedOrder.id != null"
                                :style="{width: '100%'}">
                            {{ $t('ticket.tickets.display.relatedOrder')}}
                          </span>
                          <v-btn class="mt-1"
                                 v-if="ticketDetails.relatedOrder.id != null"
                                 color="secondary"
                                 depressed
                                 outlined
                                 :to="{name: 'orders.show', params: {orderId: ticketDetails.relatedOrder.id}}"
                                 tile>
                            {{ $t('ticket.tickets.display.order')}} #{{ ticketDetails.relatedOrder.id }}
                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <TicketImageBatch
                            :ticketImages="ticketDetails.images"
                            :ticket-id="ticketId"
                            :reload-request="reloadTicketDetails"
                          />
                          <v-file-input
                            v-model="imageToAdd"
                            ref="input"
                            v-show="false"
                            accept="image/png, image/jpeg, image/jpg"
                          />
                          <v-responsive class="pa-1">
                            <v-sheet outlined
                                     height="100px"
                                     :style="{borderColor: $vuetify.theme.themes.light.primary}"
                                     role="button"
                                     @drop.prevent="onDrop"
                                     @dragenter.prevent
                                     @dragleave.prevent="glow = false"
                                     @dragover.prevent="glow = true"
                                     @click.stop="inputClick"
                                     class="d-flex justify-center align-center"
                                     :class="[glow ? 'glow' : null]">
                              <div class="pa-1 overflow-hidden">
                                <k-btn @click.stop="inputClick"
                                       :loading="isLoading">
                                  {{ $t('actions.upload') }}
                                  <v-icon right>$upload</v-icon>
                                </k-btn>
                              </div>
                            </v-sheet>
                          </v-responsive>
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </v-col>
              </v-row>
            </k-display-group>
          </v-col>
          <v-col lg="5" cols="12">
            <div class="comments-wrapper">
              <Comments :title="$t('ticket.commentsTitle')"
                        :comments-text-area-label-text="$t('ticket.commentsTextAreaLabelText')"
                        :add-button-text="$t('ticket.commentsButtonText')"
                        :index-request="getComments"
                        :create-request="addComment"/>
            </div>
          </v-col>
        </v-row>
      </v-fade-transition>
    </template>
  </page-layout>
</template>

<script lang="js">
import { TicketDetail } from '@/application/models/Ticket.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import ticketStatus from '@/application/enums/ticketStatus.js';
import TicketImageBatch from '@/modules/tickets/components/TicketImageGroup.vue';
import KBtn from '@/components/KButton.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import Comments from '@/components/comments/Comments.vue';
import { addComment, getComments } from '@/modules/tickets/api/ticket.js';
import { uploadImage, show } from '@/modules/tickets/api/ticket.js';
import { mapGetters } from 'vuex';

export default {
  name: 'DetailLayout',
  components: {
    Comments,
    KTitle,
    PageLayout,
    TicketImageBatch,
    KDisplayGroup,
    KSubtitle,
    KTextDisplay,
    KBtn,
  },
  props: {
    ticketId: {
      type: [Number, String],
      required: true,
    },
    ticketDetails: {
      type: TicketDetail,
      required: true,
    },
  },
  data() {
    return {
      imageToAdd: null,
      glow: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('authorisation', { clientId: 'getCurrentClientId' }),
    status() {
      return this.$t(`ticket.ticketStatus.${Object.keys(ticketStatus)
        .find(key => ticketStatus[key] === this.ticketDetails.ticketStatusId)}`);
    },
    user() {
      const phone = this.ticketDetails.contactInfo.phone != null ? this.ticketDetails.contactInfo.phone : '-';
      return this.ticketDetails.contactInfo.name + '\n' + phone + '\n' + this.ticketDetails.contactInfo.email;
    },
  },
  watch: {
    imageToAdd: {
      handler(value) {
        this.uploadImage(value);
      },
    },
  },
  methods: {
    onDrop(event) {
      this.glow = false;
      const file = Array.from(event.dataTransfer.files);
      this.imageToAdd = file[0];
    },
    inputClick(...args) {
      return this.$refs?.input?.$refs.input.click(...args);
    },
    async uploadImage(image) {
      if (image === null) {
        return;
      }

      this.isLoading = true;
      await uploadImage(this.clientId, this.ticketId, image);
      this.imageToAdd = null;
      await this.reloadTicketDetails();
      this.isLoading = false;
    },
    async reloadTicketDetails() {
      const { data: { data } } = await show(this.clientId, this.ticketId);
      this.ticketDetails = new TicketDetail().mapResponse(data);
      this.ticketDetails.isLoaded = true;
    },
    async addComment(comment) {
      await addComment(this.ticketId, comment);
    },
    async getComments() {
      return await getComments(this.ticketId);
    },
  },
};
</script>

<style>
.glow {
  box-shadow: inset 0 0 24px #cccccc
}

.comments-wrapper {
  height:   calc(100vh - 220px);
  overflow: auto;
}
</style>
