<template>
  <div class="d-flex flex-wrap">
    <TicketImage
      v-for="(img, index) in ticketImages"
      :key="index"
      :image="img"
      :ticket-id="ticketId"
      :reload-request="reloadRequest"
    />
  </div>
</template>

<script>

import TicketImage from '@/modules/tickets/components/TicketImage.vue';

export default {
  name: 'TicketImageGroup',
  components: { TicketImage },
  props: {
    ticketImages: {
      type: Array,
      required: true,
    },
    ticketId: {
      type: [Number, String],
      required: true
    },
    reloadRequest: {
      type: Function,
      required: true
    }
  },
};
</script>
